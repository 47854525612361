
import { Component, Vue, Watch } from "vue-property-decorator";
import _ from "underscore";
import { findAppFiles } from "@/api/witheredHeath";
import TheDocument from "../../components/inventory/TheDocuments.vue";
import Notify from "@/utils/notifications";

@Component({
  components: {
    TheDocument,
  },
})
export default class OperationFiles extends Vue {
  loading = true;
  fileList = [] as string[];
  appId = this.$route.params.appId || sessionStorage.getItem("appId");
  appName = this.$route.params.appName || sessionStorage.getItem("appName");
  async getFiles() {
    try {
      this.fileList = await findAppFiles(this.appId);
      this.loading = false;
    } catch (err) {
      Notify.gebServerError(err);
    }
  }

  downloadFile = name => {
    window.open(name, "_blank");
  };

  async mounted() {
    await this.getFiles();
    this.loading = false;
  }

  created() {
    const title = "Documentos";
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Operaciones",
        to: {
          name: "operations",
        },
      },
      {
        text: "Documentos",
      },
    ]);
  }

  @Watch("$route.params.appId", { immediate: true })
  onAppChange(newApp: any) {
    if (newApp) {
      this.appId = newApp;
      sessionStorage.setItem("appId", newApp);
      sessionStorage.setItem("appName", this.$route.params.appName);
    }
  }
}
