import WitheredHeathClient from "./withered_heath_client";
import _ from "underscore";

const enum Service {
  App = "/app",
  AppItem = "/app/{id}",
  AppFiles = "/app/{id}/fileList",
  Events = "/events",
  EventItem = "/events/{id}",
  EventRules = "/events/{id}/rule",
  EventRuleItem = "/eventsRule/{id}",
  Platform = "/platform",
  Rule = "/rule",
}

interface Pagination {
  offset?: number;
  limit?: number;
  sort?: string;
}

interface FindItems extends Pagination {
  id?: number;
  name?: string;
}

interface FindAppsItems extends Pagination {
  id?: number;
  name?: string;
  active?: 0 | 1;
}

export interface Payload<T> {
  count: number;
  rows: T[];
}

export interface Platform {
  id: number;
  name: string;
  "created-at": Date;
  "updated-at": Date;
}

export interface App {
  id: number;
  name: string;
  "external-app-id": string;
  "internal-sheet-id": string;
  active: boolean;
  "created-at": Date;
  "updated-at": Date;
  "platform-id": number;
  platform: Platform;
}

export interface Events {
  id: number;
  name: string;
  code: string;
  type: string;
  geo: string;
  masterColumn: string;
  "created-at": Date;
  "updated-at": Date;
  "app-id": number;
  app: App;
}

export interface Rule {
  id: number;
  name: string;
  description: string;
  code: string;
  type: string;
  "created-at": Date;
  "updated-at": Date;
}

export interface EventRule {
  "event-rule-id": number;
  "report-column-name": string;
  value: string;
  "created-at": Date;
  "updated-at": Date;
  "rule-id": number;
  rule: Rule;
}

export type PostItem<T> = Omit<T, "id" | "created-at" | "updated-at">;

export async function findPlatforms(params?: FindItems) {
  const client = WitheredHeathClient.getInstance();
  const response = await client.find(Service.Platform, { params });
  return response.data.data as Payload<Platform>;
}

export async function postApp(params: PostItem<App>) {
  const client = WitheredHeathClient.getInstance();
  return await client.post(Service.App, params);
}

export async function findApps(params?: FindAppsItems) {
  const client = WitheredHeathClient.getInstance();
  const response = await client.find(Service.App, { params });
  return response.data.data as Payload<App>;
}

export async function patchApp(id: number, params: PostItem<App>) {
  const client = WitheredHeathClient.getInstance();
  return await client.patch(
    Service.AppItem.replace("{id}", id.toString()),
    params
  );
}

export async function findAppFiles(id: number) {
  const client = WitheredHeathClient.getInstance();
  const response = await client.find(
    Service.AppFiles.replace("{id}", id.toString())
  );
  return response.data.data as string[];
}

export async function postEvent(params: PostItem<Events>) {
  params.type = "platform event";
  const client = WitheredHeathClient.getInstance();
  return await client.post(Service.Events, params);
}

export async function findEvents(params?: FindItems) {
  const client = WitheredHeathClient.getInstance();
  const response = await client.find(Service.Events, { params });
  return response.data.data as Payload<Events>;
}

export async function patchEvent(id: number, params: PostItem<Events>) {
  const client = WitheredHeathClient.getInstance();
  return await client.patch(
    Service.EventItem.replace("{id}", id.toString()),
    params
  );
}

export async function deleteEvent(id: number) {
  const client = WitheredHeathClient.getInstance();
  return await client.delete(Service.EventItem.replace("{id}", id.toString()));
}

export async function postEventRule(eventId: number, params: EventRule) {
  const client = WitheredHeathClient.getInstance();
  return await client.post(
    Service.EventRules.replace("{id}", eventId.toString()),
    params
  );
}

export async function findEventRules(eventId: number) {
  const client = WitheredHeathClient.getInstance();
  const response = await client.find(
    Service.EventRules.replace("{id}", eventId.toString()),
    {}
  );
  const data = response.data.data;
  return data as Payload<EventRule>;
}

export async function patchEventRule(id: number, params: PostItem<EventRule>) {
  const client = WitheredHeathClient.getInstance();
  return await client.patch(
    Service.EventRuleItem.replace("{id}", id.toString()),
    params
  );
}

export async function deleteEventRule(id: number) {
  const client = WitheredHeathClient.getInstance();
  return await client.delete(
    Service.EventRuleItem.replace("{id}", id.toString())
  );
}

export async function findRules(params?: FindItems) {
  const client = WitheredHeathClient.getInstance();
  const response = await client.find(Service.Rule, { params });
  return response.data.data as Payload<Rule>;
}
